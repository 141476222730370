export const themeOverrides = {
	MuiPaper: {
		elevation1: {
			boxShadow: "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
		}
	},
	MuiContainer: ({
		root: {
			width: "unset"
		},
		maxWidthXl: {
			'@media (min-width: 1920px)': {
				maxWidth: "unset",
				width: "unset"
			}
		}
	})
};

export const themeOverridesDark = {
	MuiContainer: ({
		root: {
			width: "unset"
		},
		maxWidthXl: {
			'@media (min-width: 1920px)': {
				maxWidth: "unset",
				width: "unset"
			}
		}
	})
};

import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName } from "@fortawesome/fontawesome-common-types";
import { SizeProp, RotateProp } from "@fortawesome/fontawesome-svg-core";

interface IconType {
	name: IconName;
	type?: 'far'|'fas';
	size?: SizeProp;
	fixedWidth?: boolean;
	rotation?:RotateProp;
}

export const Icon = (props:IconType) => {
	const {name, type, size, fixedWidth} = props;
	//name: IconName, type: 'far'|'fas' = 'far', size: SizeProp = 'lg', fixedWidth:boolean = true
	return <FontAwesomeIcon icon={[type || "far", name]} size={size || "1x"} fixedWidth={fixedWidth===undefined?true:false}/>
}


export const IconRotate = (props:IconType) => {
	const {name, type, size, rotation, fixedWidth} = props;
	//name: IconName, type: 'far'|'fas' = 'far', size: SizeProp = 'lg', fixedWidth:boolean = true
	return <FontAwesomeIcon icon={[type || "far", name]} size={size || "1x"} rotation={rotation || 90} fixedWidth={fixedWidth===undefined?true:false}/>
}


export const IconSpin = (props:IconType) => {
	const {name, type, size, fixedWidth} = props;
	//name: IconName, type: 'far'|'fas' = 'far', size: SizeProp = 'lg', fixedWidth:boolean = true
	return <FontAwesomeIcon icon={[type || "far", name]} size={size || "1x"} fixedWidth={fixedWidth===undefined?true:false} pulse />
}

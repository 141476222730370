import React from 'react';
import { Link } from 'react-router-dom';

//material-ui
import { Button, IconButton } from '@material-ui/core';

function PageButtons(props: any) {

	return (
		<>
			{props.pageButtons.map(
				(pageBtn: any, i: number) =>
					pageBtn.link ? (
						<Link
							key={pageBtn.key}
							style={{
								textDecoration: 'none',
								width: 'auto',
								minWidth: '25%',
								display: 'flex',
								color: '#FFF'
							}}
							to={pageBtn.link}
							title={pageBtn.label}
						>
							<IconButton color="inherit">{pageBtn.icon}</IconButton>
						</Link>
					) : pageBtn.value ? (
						<Button
							style={{ whiteSpace: 'nowrap', backgroundColor: '#FFFFFF' }}
							size="small"
							color="secondary"
							variant="text"
							onClick={() => pageBtn.callback()}
							key={pageBtn.key}
							title={pageBtn.label}
						>
							{pageBtn.value}
						</Button>
					) : pageBtn.icon ? (
								<IconButton color="inherit" onClick={() => pageBtn.callback()} key={pageBtn.key} title={pageBtn.label}>
									{pageBtn.icon}
								</IconButton>
					): null
			)}
		</>
	);
}

export default PageButtons;
